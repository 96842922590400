<template>
  <div class="contact">
    <h2 class="contact__heading">
      {{ $t("contact").heading }}
    </h2>
    <div class="contact__text">
      <p class="contact__paragraph">
        {{ $t("contact").text[0] }}
      </p>
      <p class="contact__paragraph">
        {{ $t("contact").text[1] }}
      </p>
      <p class="contact__paragraph">
        {{ $t("contact").text[2] }}
      </p>
    </div>
    <app-footer class="contact__footer"></app-footer>
  </div>
</template>

<script>
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "Vocabulary",
  components: {
    AppFooter
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.contact {
  position: relative;
  // padding: 12rem calc(50vw - 0.5 * #{$width-center});
  padding-top: 12rem;
  width: 100vw;
  min-height: calc(100vh + 6rem);
  color: $color-white;

  background-color: #131313;

  @include grid-template-columns;
  grid-template-rows: max-content max-content;

  @include respond(phone) {
    min-height: calc(100vh + 30rem);
    background-color: $color-black;
  }

  &__heading {
    grid-column: 3 / 4;

    line-height: 1;
    margin-bottom: 5rem;
    font-weight: 400;
  }

  &__text {
    grid-column: 3 / 4;

    font-size: 2.6rem;
    color: #acacac;

    @include respond(phone) {
      color: #ccc;
    }
  }

  &__paragraph {
    margin-bottom: 2.5rem;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    background-color: $color-black;

    @include respond(phone) {
      padding-bottom: 10rem;
      background-color: #131313;
    }
  }
}
</style>
